import { Component} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  menuItems = [
    { icon: 'icon-dashboard.svg', route: 'dashboard', label: 'Dashboard' },
    { icon: 'control-user.svg', route: '', label: 'Controle de Usuários' },
    { icon: 'control-perfil.svg', route: '', label: 'Controle de Perfil' },
    { icon: 'control-param.svg', route: 'controle-parametros', label: 'Controle de Parâmetros' },
    { icon: 'control-bloqueios.svg', route: 'controle-bloqueios', label: 'Controle de Bloqueios' },
    { icon: 'control-noticias.svg', route: 'controle-noticias', label: 'Controle de Notícias' },
    { icon: 'control-galeria.svg', route: 'controle-galeria', label: 'Controle de Galeria' },
    { icon: 'faq-admin.svg', route: '', label: 'Perguntas Frequentes' },
    { icon: 'links-rodape.svg', route: '', label: 'Controle de Links Rodapé' }
  ];
}

