<div class="dashboard-container">
  <nav class="sidebar">
    <ul>
      <div class="sidebar__itens" *ngFor="let item of menuItems">
        <div class="sidebar__itens__icons" [routerLinkActive]="['active-link']">
          <img [src]="'../../../../assets/imgs/svg/' + item.icon">
          <li [routerLink]="item.route">{{ item.label }}</li>
        </div>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </ul>
  </nav>

  <div class="content"></div>
</div>
