<div id="header-admin-principal" role="group" class="header-admin">
  <app-acessibilidade></app-acessibilidade>
  <div id="header-principal-conteudo" role="group" fxLayout="row" fxLayoutAlign="space-between center"
    class="header__container">
    <div id="conteudo-header-logo-sabesp" tabindex="0" role="group" class="header__logo-wrapper" [routerLink]="['']">
      <agv-icon id="img-logo-sabesp" class="logo-sabesp default-mode" [name]="'logo'" title="Logo da Sabesp"></agv-icon>
      <agv-icon id="img-logo-sabesp-white" class="logo-sabesp dark-mode" [name]="'logo'"></agv-icon>
      <p-divider fxHide.xs layout="vertical" class="header__divider"></p-divider>
      <div id="conteudo-texto-logo" role="group" class="header__text-content">
        <h1 id="texto-descritivo-logo">Agência Virtual</h1>
      </div>
    </div>

    <!-- <div *ngIf="estaLogado" class="header__tab_group">
      <nav>
        <a [routerLink]="['/admin/dashboard']">Home</a>
        <a>Notícias</a>
        <a [routerLink]="['/admin/galeria']">Galeria</a>

        <a [routerLink]="['/admin/bloqueio']">Bloqueio</a>
        <a [routerLink]="['/admin/parametros']">Parametrização</a>

        <a>Gestão de Perfis</a>
        <a [routerLink]="['/admin/gestao-usuarios']">Gestão de Usuários</a>
      </nav>
    </div> -->

    <div id="conteudo-header-logar-desktop" role="group" tabindex="0" fxHide fxShow.gt-md
      class="header__button-actions">
      <a #buttonToggle id="texto-logar" (click)="$event.stopPropagation(); openUserMenu()"
        title="Logar na agência virtual" tabindex="0"
        aria-label="você está no ícone canto superior direito da agência virtual, clique para expandir o menu do usuário ou para logar se não estiver logado">
        <span class="texto-header">{{ estaLogado ? "Fulano" : "Entrar" }}
        </span>
        <mat-icon id="icone-loga"> account_circle </mat-icon>
      </a>
    </div>

    <div id="conteudo-header-mobile" role="group" fxHide.gt-md fxShow.xs class="header__toolbars-icons">
      <agv-header-search></agv-header-search>
      <button mat-icon-button id="botao-abrir-sidenav" role="button" class="header-menu__button" title="Menu">
      <agv-icon  [ngClass]="{'dark-theme': currentTheme == 'theme-dark'}" id="img-menu" class="header-menu-icon default-mode" [name]="'icn_menu_mobile'" title="Abrir Menu"></agv-icon>
        <mat-icon id="img-menu" class="header-menu-close">close</mat-icon>
      </button>
    </div>
  </div>
</div>
<ng-template [ngIf]="isMenuUserOpen && estaLogado">
  <div #userMenu class="menu-header-user__content">
    <div class="header">
      <button (click)="isMenuUserOpen = false" mat-icon-button tabindex="0"
        aria-label="botão para fechar aba de menu de usuário ">
        <mat-icon> closed</mat-icon>
      </button>
    </div>
    <!-- <div tabindex="0" class="icon" >
      <img (mouseover)="showEditaFoto = true"  alt="user" />
      <a (click)="$event.stopPropagation(); fileInput.click()">
        <img *ngIf="showEditaFoto" class="edita-foto"alt="edit" tabindex="0" aria-label="adicionar foto , clicando aqui será aberto seus arquivos pessoais de sua máquina para adição de foto de perfil">
        <input #fileInput accept="image/*" type="file" style="display:none;" />
      </a>
    </div> -->

    <div class="user-list-options">
      <!-- <div tabindex="0" class="item" [ngStyle]="{'padding-bottom': name.length >= 21 ? '10px': '0'}">
        <span class="nome-cliente">{{name}}</span>
      </div>
      <a tabindex="0" class="item">
        <span>Meus dados</span>
      </a> -->
      <!-- <a tabindex="0" class="item">
        <span>Permissões de acesso</span>
      </a> -->
      <!-- <a tabindex="0" class="item">
        <span (click)="redirectAlteraSenha()">Alterar senha</span>
      </a> -->
      <a tabindex="0" (click)="signOut()" class="item">
        <span>Sair</span>
      </a>
    </div>
  </div>
</ng-template>
