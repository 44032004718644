import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ObservableInput, throwError } from 'rxjs';
import { DialogErrorComponent } from '../shared/components/dialog-error/dialog-error.component';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  constructor(private readonly dialog: MatDialog, private router: Router) {}

  public handlerError(error: HttpErrorResponse): ObservableInput<any> {
    let errorMessage: string;
    let deniedImage: string = 'icon_atencao';
    let title: string = 'Erro na conexão';
    let cssCustom: string = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      if (
        !error.url?.endsWith(
          '/service-gestao/home-noticia/ultimas-noticias.json'
        )
      ) {
        switch (error.status) {
          case 400:
            if (!error.url?.includes('v1/vazamentos/efetivar')) {
              errorMessage = `Por favor tente novamente e se o erro persistir entre em contato com um de nossos <a href="/canais-atendimento">Canais de Atendimento</a>.`;
              console.error(error.error?.description);
            }else{
              errorMessage = '';
            }

            break;
          case 401:
            if (!error.url?.includes('v1/campanha/registrar')) {
              title = `Entre novamente`;
              errorMessage = `Desculpe, mas para a sua segurança, o seu tempo conectado esgotou. <strong> Para continuar, entre novamente com o seu CPF e senha.</strong>`;
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.router.navigate(['/login']);
                sessionStorage.clear();
                localStorage.clear();
              }, 1000);
              console.error(error.error?.description);
            } else {
              errorMessage = ``;
            }
            break;
          case 403:
            if (
              !error.url?.includes('v1/religar/valida/fornecimento') &&
              !error.url?.endsWith('v2/completa/fatura/parcelamento/simular') &&
              !error.url?.endsWith(
                'v2/completa/fatura/reparcelamento/simular'
              ) &&
              !error.url?.includes(
                'v1/troca/titularidade/check/fornecimento'
              ) &&
              !error.url?.includes('v1/falta/agua/validar/fornecimento') &&
              !error.url?.includes('v1/desligamento/fornecimento') &&
              !error.url?.includes('v1/generic/valida/fornecimento') &&
              !error.url?.includes('/v1/consertos/efetivar') &&
              !error.url?.includes('v1/generic/busca/fornecimento') &&
              !error.url?.includes('v1/campanha/registrar') &&
              !error.url?.includes('v1/vazamentos/efetivar')
            ) {
              errorMessage = `Acesso negado: Por favor faça login novamente.`;
              console.error(error.error?.description);
            } else {
              errorMessage = ``;
            }

            break;
          case 404:
            if (
              !error.url?.endsWith(
                '/service-gestao/home-noticia/ultimas-noticias.json'
              ) &&
              !error.url?.includes('v1/desligamento/fornecimento') &&
              !error.url?.includes('v1/generic/busca/fornecimento') &&
              !error.url?.includes('v1/falta/agua/validar/fornecimento')
            ) {
              errorMessage = `Não foi possível encontrar o serviço! Tente novamente mais tarde.`;
              console.error(error.error?.description);
            } else {
              errorMessage = ``;
            }
            break;
          case 409:
            if (error.url?.endsWith('/v1/primeiro/acesso/cliente/valida')) {
              errorMessage = '';
            } else {
              errorMessage = `${error.error?.description}`;
              console.error(error.error?.description);
            }

            break;
          case 410:
            if (error.url?.endsWith('/v1/primeiro/acesso/cliente/valida')) {
              errorMessage = '';
            } else {
              errorMessage = `${error.error?.description}`;
              console.error(error.error?.description);
            }

            break;
          case 411:
            if (error.url?.endsWith('/v1/primeiro/acesso/cliente/valida')) {
              errorMessage = '';
            } else {
              errorMessage = `${error.error?.description}`;
              console.error(error.error?.description);
            }

            break;
          case 412:
            errorMessage = `Pré-condição falhou: Por favor entre em contato com suporte.`;
            console.error(error.error?.description);

            break;
          case 500:
            errorMessage = `Erro interno do servidor: Por favor entre em contato com suporte.`;
            console.error(error.error?.description);

            break;
          case 503:
            errorMessage = `Serviço indisponível: Por favor aguarde alguns minutos e tente novamente.`;
            console.error(error.error?.description);

            break;
          case 0:
            if (
              error.url?.endsWith(
                '/service-gestao/home-noticia/ultimas-noticias.json'
              )
            ) {
              errorMessage = ``;
            } else if (error.url?.endsWith('/v1/home/noticia/galeria')) {
              errorMessage = `Erro ao adicionar galeria: Por favor tente novamente ou use um conversor de imagem para enviar a foto e tente novamente.`;
              console.error(error.error?.description);
            } else {
              errorMessage = `Não foi possível fazer a chamada do serviço. Por favor tente novamente mais tarde`;
              console.error(error.error?.description);
            }

            break;
          default:
            if (error.url?.endsWith('/v1/home/noticia/galeria')) {
              errorMessage = `Erro ao adicionar galeria: Por favor tente novamente ou use um conversor de imagem para enviar a foto e tente novamente.`;
            } else if (
              error.url?.endsWith(
                '/service-gestao/home-noticia/ultimas-noticias.json'
              )
            ) {
              errorMessage = ``;
            } else {
              errorMessage =
                'Houve um erro ao tentar processar o seu pedido. Por favor, tente novamente.';
            }
        }
      } else {
        errorMessage = ``;
      }
    }
    if (errorMessage !== '') {
      this.dialog.open(DialogErrorComponent, {
        data: {
          img: deniedImage,
          title: title,
          content: errorMessage,
        },
        panelClass: `custom-dialog-error${cssCustom}`,
      });
    }
    const err = new Error(error.message);
    return throwError(() => err);
  }
}
